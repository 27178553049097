.shareableSentYouMessage {
  text-transform: capitalize;
  text-align: center;
  font-weight: bolder;
  font-size: 15px;
  grid-column: 2/3;
  color: rgb(0, 100, 167);
  margin: 10px 0;
  padding: 0;
}
.copyLinkDiv {
  border: none;
  color: var(--black-color);
  display: flex;
  width: 346px;
  padding: 4px 0;
  grid-column: 2/3;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.copyLinkDiv input {
  width: 162px;
  height: 29px;
  color: #0645ad;
}
.copyLinkDiv span {
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  font-size: 10px;
}
.copyLinkDiv a {
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: #0df400;
  /* border: 4px solid #067000; */
  border-radius: 10px;
  padding: 9px 20px;
  color: #067000;
  cursor: pointer;
}
.copyLinkDiv a:hover {
  cursor: pointer;
}
.copyLinkDiv a::-moz-selection {
  color: none;
  background: none;
}
.copyLinkDiv a::selection {
  color: none;
  background: none;
}
.copyLinkDiv a::-moz-selection {
  color: none;
  background: none;
}

/* Copied To Clipboard Confirmation */
.copiedConfirmation {
  width: 0px;
  height: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
  transform: translateY(-34px);
  opacity: 0;
}
.copiedConfirmation .copiedConfirmationTriangle {
  top: 15px;
  right: 60px;
  content: "";
  /* display: block; */
  position: absolute;
  background-color: transparent;
  width: 0;
  height: 0;
  border-bottom: 6px solid var(--main-color);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  pointer-events: none;
  opacity: 1;
  z-index: 998;
  transition: all 500ms ease;
}
.copiedConfirmation .copiedConfirmationText {
  text-transform: uppercase;
  line-height: 24px;
  font-size: 12px;
  font-weight: bolder;
  top: 20px;
  right: 8px;
  margin: 0;
  /* display: block; */
  position: absolute;
  background-color: var(--main-color);
  border-radius: 10px;
  width: 130px;
  height: 24px;
  pointer-events: none;
  opacity: 1;
  z-index: 999;
}
@-webkit-keyframes animateCopied {
  0% {
  }
  20% {
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    opacity: 0;
  }
}
@keyframes animateCopied {
  0% {
  }
  20% {
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    opacity: 0;
  }
}
/* End of Copied To Clipboard Confirmation */

.or_span_text {
  grid-column: 2/3;
  padding-bottom: 8px;
  text-transform: capitalize;
}

.unbold {
  text-transform: lowercase;
  font-weight: lighter;
  font-size: 15px;
}
.autoRow {
  margin-top: 12px;
  grid-row: auto;
}
.shareableLabel {
  grid-column: 1/2;
  color: var(--black-color);
  background-color: transparent;
  justify-self: flex-end;
  margin-right: 40px;
}

.shareableItem {
  background-color: #fff;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  width: 346px;
  height: 22px;
  display: grid;
  grid-column: 2/3;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
}
.shareableItemItemMarginTop {
  margin-top: -4px;
}
.shareableItemAmount {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.shareableDollarSign {
  color: var(--black-color);
  background-color: transparent;
  margin: 0 56px 0 0;
}
.shareableInput {
  border: none;
  background-color: #fff;
  color: #000;
  font-size: 14px;
}

.themText {
  background-color: #fff;
  color: #000;
}
.shareableName {
  background-color: #fff;
  color: rgb(0, 100, 167);
  font-weight: bolder;
  font-size: 15px;
}

/* Subtotal Container */
.shareableSubtotalContainer {
  padding-top: 20px;
  grid-column: 2/3;
  background-color: #fff;
  width: 346px;
  height: 40px;
  display: grid;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  grid-template-columns: 4fr 2fr;
  align-items: center;
  justify-content: center;
}
.shareableSubtotalLabel {
  color: var(--black-color);
  background-color: #fff;
  justify-self: flex-end;
  margin: 0 8px 0 0;
}
.shareableSubtotalDollarSign {
  margin: 0 56px 0 0;
  color: var(--black-color);
  background-color: #fff;
  justify-self: flex-end;
}

/* Taxed Container */
.shareableTotalTaxedContainer {
  grid-column: 2/3;
  background-color: #fff;
  width: 346px;
  height: 20px;
  display: grid;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  grid-template-columns: 4fr 2fr;
  align-items: center;
  justify-content: center;
}
.shareableTotalTaxedLabel {
  color: var(--black-color);
  background-color: #fff;
  justify-self: flex-end;
  margin: 0 8px 0 0;
}
.shareableTotalTaxedDollarSign {
  margin: 0 56px 0 0;
  color: var(--black-color);
  background-color: #fff;
  justify-self: flex-end;
}

/* Final Total Container */
.shareableFinalTotalContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  grid-column: 2/3;
  background-color: #fff;
  width: 346px;
  height: 40px;
  display: grid;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  grid-template-columns: 4fr 2fr;
  align-items: center;
  justify-content: center;
}
.shareableFinalTotalLabel {
  color: var(--black-color);
  background-color: #fff;
  justify-self: flex-end;
  margin: 0 8px 0 0;
}
.shareableFinalTotalDollarSign {
  margin: 0 56px 0 0;
  color: var(--black-color);
  background-color: #fff;
  justify-self: flex-end;
}
