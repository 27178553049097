/* This Css is used for anything that the users will have to read, like articles and tutorials */
.articleContainer {
  /* padding-top: 40px; */
  padding-bottom: 40px;
  background-color: #1a1a1a;
  width: 100%;

  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  gap: 20px;
}
.articleContainer > img {
  background-color: transparent;
}
.article_content {
  grid-column: 2/3;
  /* margin-top: 20px; */
  background-color: #fff;
  color: #fff;
  padding-bottom: 30px;
}
.article_content h1 {
  font-size: 38px;
  text-align: left;
  font-weight: 900;
  background-color: #fff;
  text-transform: capitalize;
  color: #000;
}
.article_content h1,
.article_content h2,
.article_content p,
.article_content img {
  margin-left: 20px;
  margin-right: 20px;
}
.article_content h2 {
  margin-bottom: 2px;
  text-transform: capitalize;
  background-color: #fff;
  color: #000;
  font-size: 22px;
  font-weight: 900;
}
.article_content p {
  margin-top: 0;
  margin-bottom: 6px;
  background-color: #fff;
  color: #000;
}
.article_content span {
  font-weight: 600;
  background-color: #fff;
  color: #000;
}
.article_content img {
  border: 1px solid black;
  margin-bottom: 10px;
  width: 350px;
}
.article_calltoaction_message {
  font-weight: 600;
  text-align: center;
  font-size: 24px;
}
.article_calltoaction_button {
  font-weight: 600;
  display: block;
  width: 340px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  margin: 0 auto;
  background-color: #0df400;
  padding: 10px;
  border-radius: 20px;
  color: #000;
  transition: all 0.2s;
  cursor: pointer;
}
.article_calltoaction_button:hover {
  background-color: #6aff00;
  cursor: pointer;
  transform: translateY(2px);
}

.article_content_steps {
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  width: 400px;
  flex-direction: column;
  justify-content: center;
}
.article_content_steps h2,
.article_content_steps img {
  align-self: center;
}

@media screen and (max-width: 1000px) {
  .articleContainer {
    padding-bottom: 0px;
    grid-template-columns: 1fr;
    gap: 0px;
  }
  .article_logo {
    margin: 0 auto;
    width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .article_logo {
    width: 400px;
  }
  .articleContainer h1 {
    font-size: 38px;
  }
}

@media screen and (max-width: 500px) {
  .article_logo {
    width: 300px;
  }
  .articleContainer h1 {
    font-size: 28px;
  }
  .article_calltoaction_message {
    font-size: 18px;
  }
  .article_calltoaction_button {
    font-size: 16px;
  }
  .article_content_steps {
    width: auto;
  }
  .article_content h1,
  .article_content h2,
  .article_content p,
  .article_content img {
    margin-left: 12px;
    margin-right: 12px;
  }
}
