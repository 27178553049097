.sharingButtons {
  margin: 0;
  width: 350px;

  display: flex;
  grid-column: 2/3;
  align-items: center;
  justify-content: space-around;
  padding: 0px;
}
.sharingButtons img {
  cursor: pointer;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: green;
  transition: all 0.1s;
}

.sharingButtons img:hover {
  transform: scale(1.1);
}
