* {
  box-sizing: border-box;
  background-color: #1a1a1a;
  color: #fff;
  font-family: sans-serif;
}

:root {
  --main-color: rgb(255, 156, 75);
  --black-color: rgb(0, 0, 0);
}

/* Bell Icon from webpushr */
#webpushr-prompt-wrapper {
  background-color: #008000;
}

#webpushr-prompt-wrapper promptbox3 {
  background-color: #008000;
}
#webpushr-prompt-wrapper promptglowingcircle {
  background-color: #008000;
  animation: circle 1s infinite;
}

/* Navigation Bar */
.navbar {
  position: fixed;
  background-color: #1d1d1d;
  width: 100%;
  height: 40px;
  z-index: 99999;
  font-size: 24px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbarMenu {
  background-color: #1d1d1d;
  margin-right: 40px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.navbarMenu::selection {
  -webkit-tap-highlight-color: transparent;
}
.navbarMenu:active {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  color: transparent;
}
.navbarMenu a {
  text-decoration: none;
  margin-right: 2px;
  text-transform: uppercase;
  font-weight: bolder;
}
.navbarMenu > span {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(-90deg);
  transition: all 0.8s;
}
.sideDrop {
  margin-top: 40px;
  right: 0;
  position: fixed;
  background-color: #1d1d1d;
  width: 200px;
  height: 100%;
  z-index: 99999;

  transform: translateX(200px);
  transition: all 0.8s;
}
.sideDropBorderTop {
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}
.sideDrop > a {
  display: block;
  text-decoration: none;
  background-color: #1d1d1d;
  padding: 10px 0;
}
.sideDrop p {
  background-color: transparent;
  text-transform: uppercase;
  font-weight: bolder;
  display: inline;
  margin-left: 10px;
  padding: 4px 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.7);
}
.sideDrop > a:hover {
  cursor: pointer;
  background-color: #575757;
}
.emailUs {
  /* For Email in nav bar */
  position: absolute;
  bottom: 60px;
  background-color: #1d1d1d;
  width: 90%;
  margin: 20px 0 0 8px;
}
.emailUs > p {
  border-bottom: none;
  /* text-align: left; */
  text-transform: none;
  font-size: 10px;
  padding: 0;
  margin: 0;
}
.emailUs_anchor {
  background-color: #1d1d1d;
  color: var(--main-color);
}
@-webkit-keyframes turnArrow {
  0% {
  }
  100% {
    transform: rotate(0deg);
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
}
@keyframes turnArrow {
  0% {
  }
  100% {
    transform: rotate(0deg);
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
}
@-webkit-keyframes bringInNav {
  0% {
  }
  100% {
    transform: translateX(0px);
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
}
@keyframes bringInNav {
  0% {
  }
  100% {
    transform: translateX(0px);
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
}

/* End of Navigation Bar */

li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.mainColor {
  color: var(--main-color);
}

input {
  padding: 6px;
  margin: 6px;
  width: 70px;
  height: 20px;
  background-color: #fff;
  color: var(--black-color);
  /* border-color: var(--black-color); */
  border: 1px solid var(--black-color);
  border-radius: 4px;
}

.marginTop {
  margin-top: 40px;
}

.container {
  padding-top: 40px;
  display: grid;
  width: 100%;
  grid-template-columns: 27fr 46fr 27fr;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
.content {
  margin-bottom: 22px;
  width: 100%;
  display: grid;
  grid-column: 2/3;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 2fr 1fr;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

.logo {
  padding: 20px 40px 40px 40px;
}

.topReceiptContainer {
  background-image: url(./Images/Top\ Receipt.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 346px;
  height: 272px;
  grid-row: 1/2;
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 193px 32px 32px;
}

.topReceiptAddress {
  text-align: center;
  align-self: end;
  justify-self: center;
  grid-row: 1/2;
  grid-column: 1/3;
  color: var(--black-color);
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  font-size: 12px;
}

.topReceiptDate {
  text-align: center;
  align-self: center;
  padding-top: 1px;
  justify-self: center;
  grid-row: 2/3;
  grid-column: 1/2;
  color: var(--black-color);
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  font-size: 12px;
}

.topReceiptTime {
  text-align: center;
  align-self: center;
  padding-top: 1px;
  justify-self: center;
  grid-row: 2/3;
  grid-column: 2/3;
  color: var(--black-color);
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  font-size: 12px;
}
.topReceiptDesc {
  text-align: center;
  align-self: center;
  padding-top: 10px;
  justify-self: center;
  grid-row: 3/4;
  grid-column: 1/2;
  color: var(--black-color);
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  font-size: 12px;
}
.topReceiptInputHeadingContainer {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding-top: 1px;
  grid-row: 3/4;
  grid-column: 2/3;
  color: var(--black-color);
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent;
  font-size: 12px;
}
.amtLabel {
  margin-left: 12px;
}
.delAddLabel {
  margin-left: 30px;
}

.topReceiptFont {
  background-color: transparent;
  color: var(--black-color);
  font-size: 12px;
  text-align: center;
}

.topReceipt {
  padding: 0;
}

.item {
  /* background-image: url("./Images/Mid\ Receipt.svg");
  background-repeat: no-repeat;
  background-size: contain; */
  background-color: #fff;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  width: 346px;
  height: 34px;
  display: grid;
  grid-column: 2/3;
  grid-template-columns: 5fr 1fr 3fr 3fr;
  align-items: center;
  justify-items: center;
}

.itemMarginTop {
  margin-top: -3px;
}

.label {
  grid-column: 1/2;
  color: var(--black-color);
  background-color: transparent;
  justify-self: end;
  margin-right: 18px;
}
.dollarSign {
  grid-column: 2/3;
  justify-self: end;
  color: var(--black-color);
  background-color: transparent;
}
.input {
  grid-column: 3/4;
}
.addDelContainer {
  margin-left: 17px;
  grid-column: 4/5;
  justify-self: start;
  display: grid;
  background-color: #fff;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.removeButton {
  grid-column: 1/2;
  background-image: url("./Images/Delete.svg");
  background-color: #fff;
  border: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.removeButton:hover {
  cursor: pointer;
  opacity: 0.5;
}
.addButton {
  grid-column: 2/3;
  /* background-image: url("./Images/Add.svg"); */
  background-color: #fff;
  border: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  stroke: black;
  stroke-width: 1000;
}
.addButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.totalTaxedContainer {
  padding-top: 18px;
  grid-column: 2/3;
  background-color: #fff;
  width: 346px;
  height: 60px;
  display: grid;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  grid-template-columns: 3fr 1fr 2fr;
  align-items: center;
  justify-content: center;
}
.totalTaxedLabel {
  color: var(--black-color);
  background-color: #fff;
  justify-self: end;
  margin-right: -34px;
}
.totalTaxedDollarSign {
  margin-right: 4px;
  color: var(--black-color);
  background-color: #fff;
  justify-self: end;
}
.totalTaxedInput {
  margin-left: 4px;
  justify-self: start;
}

.finalTotalContainer {
  grid-column: 2/3;
  background-color: #fff;
  width: 346px;
  height: 40px;
  display: grid;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  grid-template-columns: 3fr 1fr 2fr;
  align-items: start;
}
.finalTotalLabel {
  color: var(--black-color);
  background-color: #fff;
  justify-self: end;
  margin-top: 2px;
  margin-right: -34px;
}
.finalTotalDollarSign {
  margin-right: 4px;
  margin-top: 2px;
  color: var(--black-color);
  background-color: #fff;
  justify-self: end;
}
.finalTotalInput {
  margin-top: 0;
  margin-left: 4px;
  justify-self: start;
}

.calculatedOutput {
  z-index: 1;
  color: var(--black-color);
  grid-column: 2/3;
  background-color: #fff;
  width: 346px;
  height: 22px;
  margin: 0;
  border-left: 2px solid black;
  border-right: 2px solid black;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calculatedOutputHighlightedText {
  color: var(--main-color);
  font-weight: bolder;
  background-color: #fff;
}
.bottomReceiptSVG {
  z-index: 0;
  margin-top: -1px;
  grid-column: 2/3;
  width: 346px;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.shareReceiptLink {
  border-left: 2px solid black;
  border-right: 2px solid black;
  color: var(--black-color);
  display: flex;
  width: 346px;
  padding: 14px 0;
  grid-column: 2/3;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.shareReceiptLink input {
  width: 162px;
  height: 29px;
}
.shareReceiptLink p {
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  font-size: 10px;
}
.shareReceiptLink a {
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: #0df400;
  /* border: 4px solid #067000; */
  border-radius: 10px;
  padding: 9px;
  color: #067000;
}
.share_this_website_div {
  padding: 8px 0;
  grid-row: 1/2;
  grid-column: 2/3;

  display: flex;
  align-items: center;
}
.share_website_p {
  margin: 0;
}

.arrow_down_left,
.arrow_down_right {
  margin-top: 0px;
  height: 17px;
}

.arrow_down_left {
  margin-right: 4px;
  transform: scaleX(-1);
}

.arrow_down_right {
  margin-left: 4px;
}

@media screen and (max-width: 1000px) {
  .container {
    grid-template-columns: 5vw 1fr 5vw;
  }
  .logo {
    grid-column: 2/3;
    margin: 24px 0 0 0;
    padding: 0;
    width: 360px;
    justify-self: center;
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  .container {
    grid-template-columns: 0vw 1fr 0vw;
    -moz-column-gap: 0;
    column-gap: 0;
  }
}
